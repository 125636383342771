import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import * as loginLangs from './domains/login';
import { defaultLocale, locales } from "./locales";
var resources = {};

function addNS(ns, r) {
  locales.forEach(function (l) {
    var _a;

    if (!resources[l]) {
      resources[l] = (_a = {}, _a[ns] = r[l], _a);
    } else {
      resources[l][ns] = r[l];
    }
  });
}

addNS('login', loginLangs);
i18n.use(LanguageDetector).use(initReactI18next).init({
  defaultNS: 'login',
  supportedLngs: locales,
  fallbackLng: defaultLocale,
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default

  },
  resources: resources
});
export default i18n;