import Cookies from "js-cookie";

var CookieStorage =
/** @class */
function () {
  function CookieStorage() {}

  CookieStorage.prototype.getItem = function (key) {
    return Cookies.get(key) || null;
  };

  CookieStorage.prototype.removeItem = function (key) {
    Cookies.remove(key, {
      path: '/'
    });

    if (this.getItem(key)) {
      Cookies.set(key, '', {
        sameSite: 'none',
        secure: true,
        expires: -1
      });
    }
  };

  CookieStorage.prototype.setItem = function (key, value) {
    Cookies.set(key, value, {
      path: '/',
      sameSite: 'none',
      secure: true
    });
  };

  return CookieStorage;
}();

export default CookieStorage;