import GalleryLayout from "./gallery/GalleryLayout";
import GridLayout from "./grid/GridLayout";
import DownloadLayout from "./download/DownloadLayout";
import MapboxLayout from "./mapbox/MapboxLayout";

export const layouts = {
    gallery: GalleryLayout,
    grid: GridLayout,
    download: DownloadLayout,
    mapbox: MapboxLayout,
};
