import React from 'react';
export default function FormLayout(_a) {
  var children = _a.children;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "container"
  }, React.createElement("div", {
    className: "row"
  }, React.createElement("div", {
    className: "col-md-5 mx-auto"
  }, React.createElement("div", {
    className: "form-container"
  }, children)))));
}