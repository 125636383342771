var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (g && (g = 0, op[0] && (_ = 0)), _) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IdentityProviders from "./IdentityProviders";
import { AxiosError } from "axios";

function LoginOr(_a) {
  var label = _a.label;
  return React.createElement("div", {
    className: "col-md-12"
  }, React.createElement("div", {
    className: "login-or text-center"
  }, React.createElement("hr", {
    className: "hr-or"
  }), React.createElement("span", {
    className: "span-or"
  }, label)));
}

export default function Login(_a) {
  var _this = this;

  var onLogin = _a.onLogin,
      oauthClient = _a.oauthClient,
      _b = _a.externalIdpOnTop,
      externalIdpOnTop = _b === void 0 ? false : _b,
      _c = _a.defaultHiddenForm,
      defaultHiddenForm = _c === void 0 ? false : _c,
      identityProvidersProps = __rest(_a, ["onLogin", "oauthClient", "externalIdpOnTop", "defaultHiddenForm"]);

  var _d = useState(false),
      loading = _d[0],
      setLoading = _d[1];

  var _e = useState(''),
      username = _e[0],
      setUsername = _e[1];

  var _f = useState(''),
      password = _f[0],
      setPassword = _f[1];

  var _g = useState([]),
      errors = _g[0],
      setErrors = _g[1];

  var _h = useState(!defaultHiddenForm),
      displayForm = _h[0],
      setDisplayForm = _h[1];

  var t = useTranslation().t;
  var onSubmit = useCallback(function (e) {
    return __awaiter(_this, void 0, void 0, function () {
      var res, e_1;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            e.preventDefault();
            setLoading(true);
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , oauthClient.login(username, password)];

          case 2:
            res = _a.sent();
            onLogin && onLogin(res);
            return [3
            /*break*/
            , 4];

          case 3:
            e_1 = _a.sent();

            if (e_1 instanceof AxiosError) {
              if (e_1.response) {
                setErrors([e_1.response.data.error_description]);
              }
            }

            setLoading(false);
            return [3
            /*break*/
            , 4];

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  }, [setLoading, setErrors, oauthClient, username, password]);
  return React.createElement(React.Fragment, null, externalIdpOnTop && React.createElement(React.Fragment, null, React.createElement("div", {
    className: "col-md-12"
  }, React.createElement("div", {
    className: "text-center"
  }, React.createElement("span", {
    className: "span-or"
  }, t('login.sign_in_using', 'Sign In using')))), React.createElement(IdentityProviders, __assign({}, identityProvidersProps))), React.createElement("div", {
    className: "form-section"
  }, externalIdpOnTop && React.createElement(LoginOr, {
    label: t('login.or', 'or')
  }), !displayForm && React.createElement(React.Fragment, null, React.createElement("div", {
    className: 'form-group'
  }, React.createElement("button", {
    className: "btn btn-block btn-light",
    onClick: function onClick() {
      return setDisplayForm(true);
    }
  }, t('login.display_login_form', 'Sign in with login/password')))), (!defaultHiddenForm || displayForm) && React.createElement("div", {
    className: 'login-form'
  }, React.createElement("form", {
    onSubmit: onSubmit
  }, React.createElement("div", {
    className: "form-group"
  }, React.createElement("label", {
    htmlFor: "username"
  }, t('login.form.username', 'Username')), React.createElement("input", {
    className: 'form-control',
    id: 'username',
    disabled: loading,
    value: username,
    onChange: function onChange(e) {
      return setUsername(e.target.value);
    },
    type: "text"
  })), React.createElement("div", {
    className: "form-group"
  }, React.createElement("label", {
    htmlFor: "password"
  }, t('login.form.password', 'Password')), React.createElement("input", {
    className: 'form-control',
    id: 'password',
    disabled: loading,
    value: password,
    onChange: function onChange(e) {
      return setPassword(e.target.value);
    },
    type: "password"
  })), errors.length > 0 && React.createElement("div", {
    className: 'alert alert-danger'
  }, React.createElement("ul", {
    className: "errors"
  }, errors.filter(function (e) {
    return e !== 'missing_access_token';
  }).map(function (e) {
    return React.createElement("li", {
      key: e
    }, e);
  }))), React.createElement("div", {
    className: "form-group"
  }, React.createElement("button", {
    disabled: loading,
    type: 'submit',
    className: 'btn btn-block btn-primary'
  }, t('login.form.submit', 'Sign In')))))), !externalIdpOnTop && React.createElement(React.Fragment, null, React.createElement(LoginOr, {
    label: t('login.or_sign_in_using', 'or Sign In using')
  }), React.createElement(IdentityProviders, __assign({}, identityProvidersProps))));
}