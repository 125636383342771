import React, { useCallback, useState } from 'react';
import SvgMenu from '../../icons/Menu.js';
export default function DashboardMenu(_a) {
  var dashboardBaseUrl = _a.dashboardBaseUrl;

  var _b = useState(false),
      open = _b[0],
      setOpen = _b[1];

  var _c = useState(false),
      openedOnce = _c[0],
      setOpenedOnce = _c[1];

  var toggleMenu = useCallback(function (e) {
    if (!openedOnce) {
      setOpenedOnce(true);
    }

    setOpen(function (p) {
      return !p;
    });
  }, [openedOnce, setOpen, setOpenedOnce]);
  var size = 45;
  var bodyPadding = 20;
  return React.createElement("div", {
    className: "services-menu".concat(open ? ' opened' : ''),
    style: {
      position: 'absolute',
      cursor: 'pointer',
      zIndex: 1000,
      borderRadius: "50%",
      border: "1px solid #CCC",
      width: size,
      height: size,
      top: bodyPadding,
      right: bodyPadding,
      padding: Math.round(size / 5)
    },
    onClick: toggleMenu
  }, React.createElement(SvgMenu, null), (open || openedOnce) && React.createElement("div", {
    className: 'services-menu-content',
    style: {
      display: open ? 'block' : 'none',
      top: size,
      right: 0,
      position: 'absolute',
      borderRadius: "10px",
      border: "1px solid #CCC",
      overflow: 'hidden'
    }
  }, React.createElement("iframe", {
    title: 'services-menu',
    src: "".concat(dashboardBaseUrl, "/menu.html"),
    seamless: true,
    style: {
      border: '0',
      minWidth: "350px",
      height: "350px"
    }
  })));
}